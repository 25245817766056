<template>
  <div id="menu">
    <div id="main">
      <div id="left">
        <div
          class="section"
          v-for="(section, i) in this.menuleft.sections"
          :key="i"
        >
          <div class="section-title">
            {{ section.name }} <span>{{ section.extra }}</span>
          </div>
          <div class="extra">{{ section.extra2 }}</div>
          <div
            class="section-items"
            v-for="(item, j) in section.items"
            :key="j"
          >
            <div class="item">
              <div class="number">
                {{ item.number }}<span v-if="item.number">.</span>
              </div>
              <div class="item-name">
                <span>{{ item.item }}</span>
                <div class="separator"></div>
              </div>

              <div class="price">
                {{ item.price }} <span v-if="item.price">EUR</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="right">
        <div
          class="section"
          v-for="(section, i) in this.menuright.sections"
          :key="i"
        >
          <div class="section-title">
            {{ section.name }} <span>{{ section.extra }}</span>
          </div>
          <div class="extra">{{ section.extra2 }}</div>
          <div
            class="section-items"
            v-for="(item, j) in section.items"
            :key="j"
          >
            <div class="item">
              <div class="number">
                {{ item.number }}<span v-if="item.number">.</span>
              </div>
              <div class="item-name">
                <span>{{ item.item }}</span>
                <div class="separator"></div>
              </div>
              <div class="price">
                {{ item.price }} <span v-if="item.price">EUR</span>
              </div>
            </div>
          </div>
        </div>
        <div id="extra-info">
          <span class="disclaimer">{{ $t("menu.disclaimer") }}</span>
          <div id="contact">
            <div id="title">{{ $t("menu.order") }}</div>
            <div id="btn-wrapper">
              <button>
                <a id="call-us" class="text-muted" href="tel:003234581431"
                  >+32 3 458 14 31</a
                >
              </button>
            </div>
          </div>
          <div id="address" class="margin-bottom-25">
            Molenstraat 7, 2550 Kontich
          </div>
          <div id="openinghours">
            <div>{{ $t("menu.days1") }}: 12:00-14:00, 17:00-21:45</div>
            <div>
              <b>{{ $t("menu.days2") }}</b>
            </div>
            <div>{{ $t("menu.days3") }}: 12:00-21:45</div>
          </div>
        </div>
      </div>
    </div>
    <div id="menus">
      <div id="title">{{ $t("menu.menus") }}</div>
      <div class="menu" v-for="(menu, i) in this.menus.menus" :key="i">
        <div class="section-title">{{ menu.title }}</div>
        <div class="menu-item" v-for="(item, j) in menu.items" :key="j">
          <div v-if="item.title" class="subtitle">{{ item.title }}</div>
          <div v-for="(name, x) in item.names" :key="x">
            {{ name }}
            <div v-if="x == 0 && item.names.length <= 2">+</div>
            <div v-if="x < 2 && item.names.length > 2">*</div>
          </div>
        </div>
      </div>
    </div>
    <div id="holidays-menu">
      <div id="title">{{ this.holidaymenus.name }}</div>
      <div id="reserve">{{ this.holidaymenus.reserve }}</div>
      <div id="info">{{ this.holidaymenus.info }}</div>
      <div class="menus-wrapper">
        <div class="menu" v-for="(menu, i) in this.holidaymenus.menus" :key="i">
          <div v-if="menu.title" class="section-title">{{ menu.title }}</div>
          <div class="menu-info">
            <div class="wrapper">
              <div>{{ menu.date }}</div>
              <div>{{ menu.price }}</div>
            </div>
            <div class="wrapper">
              <div>{{ menu.takeaway }}</div>
              <div>{{ menu.takeawayinfo }}</div>
            </div>
          </div>
          <div class="menu-item" v-for="(item, j) in menu.items" :key="j">
            <div v-if="item.title" class="subtitle">{{ item.title }}</div>
            <div v-for="(name, x) in item.names" :key="x">
              <span v-if="item.names.length == 1 || x == 1">-&nbsp;</span
              >{{ name
              }}<span v-if="item.names.length == 1 || x == 1">&nbsp;-</span>
            </div>
          </div>
          <div class="menu-extra" v-if="menu.extra">{{ menu.extra }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sectionsleftnl from "../assets/json/nl/menuleft.json";
import sectionsrightnl from "../assets/json/nl/menuright.json";
import menusnl from "../assets/json/nl/menus.json";
import holidaymenusnl from "../assets/json/nl/holidaymenus.json";
import sectionsleften from "../assets/json/en/menuleft.json";
import sectionsrighten from "../assets/json/en/menuright.json";
import menusen from "../assets/json/en/menus.json";
import holidaymenusen from "../assets/json/en/holidaymenus.json";

export default {
  name: "Menu",
  data() {
    return {
      lang: "nl",
      menuleft: [],
      menuright: [],
      menus: [],
      holidaymenus: [],
      menuleftnl: sectionsleftnl,
      menurightnl: sectionsrightnl,
      menusnl: menusnl,
      menuleften: sectionsleften,
      menurighten: sectionsrighten,
      menusen: menusen,
      holidaymenusnl: holidaymenusnl,
      holidaymenusen: holidaymenusen,
    };
  },
  mounted() {
    this.setMenuItems(this.$i18n.locale);
  },
  methods: {
    setMenuItems(lang) {
      if (lang == "nl") {
        this.menuleft = this.menuleftnl;
        this.menuright = this.menurightnl;
        this.menus = this.menusnl;
        //this.holidaymenus = this.holidaymenusnl;
      } else if (lang == "en") {
        this.menuleft = this.menuleften;
        this.menuright = this.menurighten;
        this.menus = this.menusen;
        //this.holidaymenus = this.holidaymenusen;
      }
    },
  },
  watch: {
    "$i18n.locale": function (newVal) {
      this.setMenuItems(newVal);
    },
  },
};
</script>

<style scoped>
#menu {
  padding: 45px 0px;
}
#main {
  display: flex;
}
#main #left,
#main #right {
  width: 50%;
  text-align: left;
  padding: 0px 30px;
  margin-bottom: 25px;
}
#menu #left {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
#menu #right {
  position: relative;
}
#menus {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 45px 0px;
}
#menu #title,
#holidays-menu #title {
  font-size: 28px;
}
#extra-info {
  position: absolute;
  bottom: 0;
  padding-right: 30px;
}
#contact {
  margin-top: 15px;
  margin-bottom: 35px;
}
#contact #title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 28px;
  color: #9d4247;
}
#contact button {
  border: 2px dotted #2c3e50;
  font-size: 16px;
  padding: 15px;
  margin-top: 5px;
  font-weight: bold;
}
#contact button:hover {
  text-decoration: underline;
}
#contact button a {
  color: #2c3e50;
}

.section {
  margin-bottom: 45px;
}
.section:last-child {
  margin-bottom: unset;
}
.section-title {
  font-size: 22px;
}
.section-title,
#menus #title,
.extra,
#holidays-menu #title {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 5px;
}
.section-title span,
.extra {
  font-size: 12px;
}
.section-items .item {
  display: flex;
  margin-bottom: 1px;
}
.extra {
  text-align: right;
}
.section-items .item .number {
  margin-right: 5px;
}
.item-name {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}
.item-name span,
.separator {
  margin-right: 5px;
}
.price {
  white-space: nowrap;
  display: flex;
  column-gap: 3px;
  align-items: end;
}
.separator {
  flex-grow: 1;
  border-bottom: dotted 1px black;
  min-height: 16px;
  /* height: 16px; */
  margin-bottom: 4px;
}
.menu {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0px 25px 25px 25px;
}
.menu .subtitle {
  font-weight: bold;
  font-size: 18px;
  text-decoration: underline;
}
.menu-item {
  margin-bottom: 5px;
}
.menu-item ~ .menu-item {
  margin-top: 15px;
}
.disclaimer {
  font-size: 14px;
  color: #9d4247;
  text-decoration: underline;
}

/*holidays*/
#holidays-menu {
  padding-bottom: 45px;
}
#holidays-menu #reserve {
  text-decoration: underline;
}
#holidays-menu #title,
#holidays-menu #reserve,
#holidays-menu #info {
  color: #9d4247;
}
#holidays-menu #reserve,
#holidays-menu #info {
  text-transform: uppercase;
  font-weight: bold;
}
#holidays-menu #info {
  margin-bottom: 15px;
}
#holidays-menu .section-title {
  font-size: 28px;
}
#holidays-menu .menus-wrapper {
  display: flex;
}
#holidays-menu .menus-wrapper .menu {
  width: 50%;
  border: unset;
  margin: unset;
}
#holidays-menu .subtitle,
#holidays-menu .menu-info {
  text-transform: uppercase;
}
#holidays-menu .menu-info {
  font-weight: bold;
  margin-bottom: 10px;
}
#holidays-menu .menu-info .wrapper {
  margin-bottom: 15px;
}
#holidays-menu .menu-extra {
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  #main {
    flex-direction: column;
  }
  #menu #left,
  #menu #right {
    width: 100%;
  }
  #menu #left {
    margin-bottom: 45px;
  }
  #menu #right,
  #extra-info {
    position: static;
  }
  .section-title span {
    white-space: pre;
  }
  .extra {
    text-align: left;
  }

  /*holidays*/
  #holidays-menu {
    padding: 25px;
  }
  #holidays-menu .menus-wrapper {
    flex-direction: column;
  }
  #holidays-menu .menus-wrapper .menu {
    width: 100%;
  }
}
</style>
